import XLSX from 'xlsx'

export class General {
  constructor(list, step = 10) {
    this.total = list.length
    this.list = list
    this.step = step
  }
  get length() {
    return this.list.length
  }
  append(item) {
    this.list.push(item)
  }
  done() {
    return this.list.length === 0
  }
  next() {
    return this.list.splice(0, this.step)
  }
}

function fixdata(data) {
  // 文件流转BinaryString
  var o = ''
  var l = 0
  var w = 10240
  for (; l < data.byteLength / w; ++l) {
    o += String.fromCharCode.apply(
      null,
      new Uint8Array(data.slice(l * w, l * w + w))
    )
  }
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
  return o
}
export function readFile(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = function(ev) {
      const data = ev.target.result
      let workbook
      try {
        // eslint-disable-next-line no-debugger
        try {
          workbook = XLSX.read(btoa(fixdata(data)), {
            type: 'base64'
          })
        } catch (error) {
          window.console.log(error)
        }
        const wsname = workbook.SheetNames[0] //取第一张表
        const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname])
        resolve(new General(ws))
        resolve()
      } catch (error) {
        window.console.warn(error)
        reject('解析失败')
      }
    }
    fileReader.readAsArrayBuffer(file)
  })
}
