<template>
  <section>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="200px"
      class="el-form-wrap"
      size="medium"
    >
      <div class="form__title">发放优惠券</div>
      <el-form-item label="发券方式" prop="type">
        <el-radio-group v-model="ruleForm.type">
          <el-radio key="1" label="1">手动</el-radio>
          <el-radio key="2" label="2">批量</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="ruleForm.type == 1">
        <el-form-item label="发放优惠券" prop="coupons">
          <div class="xa-txt-13 xa-txt-secondary">
            优惠券将直接发放给客户，不需要领取。(【极飞服务】小程序，我的=>优惠券)
          </div>
          <div class="xa-txt-blue xa-cursor" @click="showSelectCoupon = true">
            选择优惠券
          </div>
          <DisplayCouponTable :datas="ruleForm.coupons" calcHeight />
        </el-form-item>
        <el-form-item label="发放对象" prop="users">
          <div class="xa-txt-blue xa-cursor" @click="showSelectUser = true">
            选择客户
          </div>
          <DisplayUserTable :datas="ruleForm.users" calcHeight />
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="发放优惠券" prop="list">
          <div class="xa-txt-placeholder">
            1、下载导入模板
            <a
              class="xa-txt-blue xa-cursor"
              href="./doc/优惠券-批量发券模板-20200428.xls"
              download="批量发放优惠券模板.xls"
              title="批量发放优惠券模板.xls"
              target="_blank"
              >下载模板</a
            >
          </div>
          <div class="xa-txt-placeholder xa-cell">
            2、按要求填写内容后导入文件
            <div class="xa-txt-blue upload-btn xa-cursor">
              <input
                v-if="!isLoading"
                type="file"
                id="upload-btn"
                @change="onFileChange"
                accept=".xls,.xlsx"
              />导入
            </div>
          </div>
          <div style="position:relative">
            <AppLineLoading v-show="isLoading" />
          </div>
          <div v-if="fileNames.length">
            <el-tag v-for="item in fileNames" :key="item" class="xa-mgr-16">{{
              item
            }}</el-tag>
          </div>
          <el-tabs>
            <el-tab-pane>
              <span slot="label"
                >解析成功
                <span class="xa-txt-blue" v-if="ruleForm.list.length">{{
                  ruleForm.list.length
                }}</span>
              </span>
              <DisplayUserInfo :datas="ruleForm.list" />
            </el-tab-pane>
            <el-tab-pane label="消息中心">
              <span slot="label"
                >解析失败
                <span class="xa-txt-red" v-if="faillist.length">{{
                  faillist.length
                }}</span>
              </span>
              <DisplayUserInfoFail :datas="faillist" />
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
      </template>
      <el-form-item label="发放说明" prop="content">
        <el-input
          type="textarea"
          v-model="ruleForm.content"
          rows="5"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-form>
    <SelectDialog
      :visible.sync="showSelectCoupon"
      @submit="onCouponSelect"
      title="请选择优惠券"
    >
      <template v-slot="{ close, submit }">
        <SelectCoupon @close="close" @submit="submit" />
      </template>
    </SelectDialog>
    <SelectDialog
      :visible.sync="showSelectUser"
      @submit="onUserSelect"
      width="512px"
      title="请选择客户"
    >
      <template v-slot="{ close, submit }">
        <SelectUser @close="close" @submit="submit" />
      </template>
    </SelectDialog>
  </section>
</template>
<script>
import { readFile, General } from './utils'
import * as Apis from '@target/apis/coupon'
export default {
  components: {
    SelectDialog: () => import('./components/SelectDialog.vue'),
    SelectCoupon: () => import('./components/SelectCoupon.vue'),
    SelectUser: () => import('./components/SelectUser.vue'),
    DisplayUserTable: () => import('./components/DisplayUserTable.vue'),
    DisplayCouponTable: () => import('./components/DisplayCouponTable.vue'),
    DisplayUserInfo: () => import('./components/DisplayUserInfo.vue'),
    DisplayUserInfoFail: () => import('./components/DisplayUserInfoFail.vue')
  },
  data() {
    return {
      showSelectCoupon: false,
      showSelectUser: false,
      isLoading: false,
      manualRules: {
        type: [
          { required: true, message: '请选择发券方式', trigger: 'change' }
        ],
        coupons: [
          { required: true, message: '请选择优惠券', trigger: 'change' }
        ],
        users: [{ required: true, message: '请选择客户', trigger: 'change' }],
        content: [
          { required: true, message: '请输入发放说明', trigger: 'blur' }
        ]
      },
      batchRules: {
        type: [
          { required: true, message: '请选择发券方式', trigger: 'change' }
        ],
        list: [{ required: true, message: '请录入名单', trigger: 'change' }],
        content: [
          { required: true, message: '请输入发放说明', trigger: 'blur' }
        ]
      },
      fileNames: [],
      faillist: [],
      ruleForm: {
        coupons: [], //【手动发放】
        users: [], //【手动发放】
        type: '1', // 【1】手动发放，【2】批量发放
        list: [], // 【批量发放】
        content: ''
      },
      general: null,
      submitGeneral: null
    }
  },
  computed: {
    rules() {
      return this.ruleForm.type === '1' ? this.manualRules : this.batchRules
    }
  },
  methods: {
    // 【手动】选择发放对象
    onUserSelect(users) {
      users = users.filter(item => {
        return !this.ruleForm.users.find(c => c.guid === item.guid)
      })
      this.ruleForm.users.push(...users)
    },
    // 【手动】选择发放的优惠券
    onCouponSelect(coupons) {
      coupons = coupons.filter(item => {
        return !this.ruleForm.coupons.find(c => c.guid === item.guid)
      })
      coupons = coupons.map(item => {
        item.cnt = 1
        return item
      })
      this.ruleForm.coupons = [...coupons, ...this.ruleForm.coupons]
    },
    // 【批量】上传批量数据文件
    async onFileChange(e) {
      const files = e.target.files
      if (this.fileNames.includes(files[0].name) === false) {
        this.fileNames.push(files[0].name)
      }
      const mGeneral = await readFile(files[0])
      this.general = () => mGeneral
      this.isLoading = true
      this.gotoHandleFileData()
    },
    // 【批量】处理批量数据文件
    gotoHandleFileData() {
      if (this.general().done()) {
        this.general = null
        this.isLoading = false
        return
      }
      const list = this.general().next()
      this.handleData(list)
    },
    // 【批量】处理批量数据文件
    async handleData(list) {
      try {
        const result = await Apis.checkCouponAndUser(list)
        this.ruleForm.list.push(...result.filter(item => item.status))
        this.faillist.push(...result.filter(item => item.status === false))
      } catch (error) {
        this.$confirm(error.msg || error.message || error, '提示', {
          showCancelButton: false,
          confirmButtonText: '确定',
          type: 'error'
        })
      }
      setTimeout(() => {
        this.gotoHandleFileData()
      }, 0)
    },
    askConfirm(message) {
      const vm = this
      return new Promise(resolve =>
        this.$msgbox({
          title: '提示',
          message,
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.showCancelButton = false
              resolve({
                update(text) {
                  instance.message = text
                },
                done(result, msg) {
                  done()
                  if (result) {
                    vm.$message({
                      type: 'success',
                      message: msg || '操作成功！'
                    })
                  }
                }
              })
            } else {
              done()
            }
          },
          callback: (action, instance) => {
            instance.confirmButtonLoading = false
            instance.confirmButtonText = '确定'
          }
        })
      )
    },
    // 【批量发券】
    async submitBatchData(uiResolve) {
      const submitGeneral = this.submitGeneral
      if (submitGeneral.done()) {
        uiResolve.done(true, '发放成功!')
      } else {
        const items = submitGeneral.next()
        try {
          await Apis.sendCoupon(items)
        } catch (error) {
          uiResolve.done(false)
          this.$notify.error({
            title: '错误',
            message: error.msg || error.message
          })
        }
        const percentage = 1 - submitGeneral.length / submitGeneral.total
        uiResolve.update(`正在处理请求，进度${(percentage * 100).toFixed(1)}%`)
        setTimeout(() => {
          this.submitBatchData(uiResolve)
        }, 0)
      }
    },
    // 【批量发券】
    async toStartSubmit(list) {
      const uiResolve = await this.askConfirm(
        `确定要提交？\n\r有${list.length}条数据要提交，请耐心等候。`
      )
      if (uiResolve) {
        this.submitGeneral = Object.create(new General(list, 1))
        setTimeout(() => {
          this.submitBatchData(uiResolve)
        }, 0)
      }
    },
    toSubumitManual() {
      const list = []
      const { coupons, users } = this.ruleForm
      for (let i = 0; i < users.length; i++) {
        for (let j = 0; j < coupons.length; j++) {
          list.push({
            coupon_guid: coupons[j].guid,
            mobile: users[i].phone,
            cnt: coupons[j].cnt
          })
        }
      }
      this.toStartSubmit(list)
    },
    // 由父页面调用
    submitForm(formName = 'ruleForm') {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (parseInt(this.ruleForm.type) === 2) {
            this.toStartSubmit(this.ruleForm.list)
          } else {
            this.toSubumitManual()
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-btn {
  position: relative;
  input {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
}
</style>
